import React from "react";
import { relativeToAbsulote } from "../../util/common";
import CtaCard from "../../components/cards/cta";
//import { Link } from "gatsby";
import Image from "../../components/Image";


const ParagraphGlossary = ({ data , ...props }) => {

    const {body, images, relationships } = data 
    //const relationships = props.relationships;
  

    const transform = (props) => ({
      title: props?.title,
      description: props?.description,
      color: props?.color?.color,
      link: props?.link,
      image: {
        alt: props?.image?.alt,
        src: props?.relationships?.image?.localFile?.publicURL,
      },
      layout: props?.layout,
    });
  
  
    return (
      <div className="">
        <div
          className="glossary-body glossary-body-paragraph"
          dangerouslySetInnerHTML={{
            __html: relativeToAbsulote(body?.processed),
          }}
        />
        <div>
          {relationships.images.map((e, i) => (
            <div className="py-4 lg:py-8 w-3/4 mx-auto max-w-[780px]" key={i}>
              <Image image={e} alt={images[i].alt} />
            </div>
          ))}
        </div>
        <div className="space-y-8 py-4">
          {relationships.formula
            .filter((e) => e.label !== null)
            .map((e, i) => (
              <Formula key={i} {...e} />
            ))}
        </div>
        {data?.title && <CtaCard {...transform(data)} />}
  
      </div>
    );
  };


const Formula = ({ label, slug, subtitle, ...props }) => {

    const pa = /([+-])/g;
    const parts = slug.split(pa);
    const parts2 = parts
      .map((e) => pipesToSpan(e.trim()))
      .map((e) => `<div class="comp">${e.trim()}</div>`);
    const slugHtml = parts2.join(" ");
  
    const tmp = subtitle ? subtitle.split(pa) : "".split(pa);
    const partsSubt = tmp
      .map((e) => pipesToSpan(e.trim()))
      .map((e) => `<div class="comp">${e.trim()}</div>`);
    const subtitleHtml = subtitle ? partsSubt.join(" ") : "";
  
    const tmp2 = label.split(pa);
    const partsLabel = tmp2
      .map((e) => pipesToSpan(e.trim()))
      .map((e) => `<div class="comp">${e.trim()}</div>`);
    const labelHtml = partsLabel.join(" ");
  
    //console.log(subtitleHtml)
  
    return (
      <div className="relative w-5/6 lg:w-3/5 mx-auto sm:my-2.5">
        <div className="sm:bg-[#7BA6FF] sm:rounded sm:rounded-[8px] w-[98%] h-[91%] absolute top-[30px] bottom-0 left-[-7px] -z-50"></div>
  
        <div className="sm:rounded sm:rounded-[8px] sm:border sm:border-gray-800 flex justify-center items-center p-8 text-xs lg:text-base font-bold  bg-white bottom-[8px] left-[8px] relative">
          <div
            className="py-8 mr-4 text-center"
            dangerouslySetInnerHTML={{ __html: labelHtml }}
          />
          <div className="px-4  mr-4">=</div>
          <div className="mr-4">
            {subtitleHtml !== "" && (
              <>
                <div
                  className="pb-4 text-center flex justify-center flex-row items-center formula-above"
                  dangerouslySetInnerHTML={{ __html: slugHtml }}
                />
                <div className="h-1 w-full border-t border-t-black" />
                <div
                  className="py-4 text-center"
                  dangerouslySetInnerHTML={{ __html: subtitleHtml }}
                />
              </>
            )}
            {subtitleHtml === "" && (
              <>
                <div
                  className=" text-center flex justify-center flex-row items-center "
                  dangerouslySetInnerHTML={{ __html: slugHtml }}
                />
                {/* <div className="h-1 w-full border-t border-t-black" /> */}
                {/* <div className="py-4 text-center" dangerouslySetInnerHTML={{ __html: subtitleHtml }} /> */}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  

const pipesToSpan = (text) => {
    //console.log('text', text)
    const s = /\|(.*?)\|/;
    const parts = text.split(s).filter((e) => e !== "");
    //console.log(parts)
    let t = "";
    parts.forEach((p, i) => {
      t +=
        i % 2 === 0
          ? `<div>${p}</div>`
          : `<div class="text-sm subscript">${p}</div>`;
    });
  
    return t;
  };

  export default ParagraphGlossary